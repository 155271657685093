import {configureStore} from "@reduxjs/toolkit";
import {infoguruApi} from "./infoguru/infoguru.api";


export const store = configureStore({
    reducer: {
        [infoguruApi.reducerPath]: infoguruApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(infoguruApi.middleware)
})