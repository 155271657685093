import { makeAutoObservable } from "mobx";
import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

class AuthStore {
    email = "";
    password = "";
    isLoading = false;
    error = "";
    isAuthenticated = false;

    constructor() {
        makeAutoObservable(this);
        this.checkAuth();  // Проверка авторизации при загрузке
    }

    setEmail(email: string) {
        this.email = email;
    }

    setPassword(password: string) {
        this.password = password;
    }

    async login() {
        this.isLoading = true;
        this.error = "";

        try {
            const response = await axios.post(`${API_URL}/api/auths/signin`, {
                email: this.email,
                password: this.password,
            });

            const { token } = response.data;  // Предположим, что в ответе приходит токен

            // Сохранение токена в cookies
            Cookies.set("auth_token", token, { expires: 7 });  // Cookie будет действительна 7 дней

            this.isAuthenticated = true;
            console.log("Login successful:", response.data);
        } catch (error: any) {
            this.error = error.response?.data?.message || "Login failed";
            this.isAuthenticated = false;
        } finally {
            this.isLoading = false;
        }
    }

    logout() {
        // Удаление токена из cookies при выходе
        Cookies.remove("auth_token");
        this.isAuthenticated = false;
    }

    checkAuth() {
        // Проверка наличия токена в cookies при загрузке приложения
        const token = Cookies.get("auth_token");
        if (token) {
            this.isAuthenticated = true;
        } else {
            this.isAuthenticated = false;
        }
    }
}

export const authStore = new AuthStore();
