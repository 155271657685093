import React from "react"
import pspu from "../images/pspu.png";
import orda from "../images/orda.jpg";
import {Link} from "react-router-dom";
import {Button} from "antd";
import mk_notebook from "../images/notebook.png";

export function GameAboutPage() {
    return (
        <div className="bg-custom-background wrapper">
            <div className=" transparent-bg flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-rounds-black text-greytext text-3xl z-10 transparent-bg">
                    Игровой модуль
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-5 z-10 transparent-bg">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10 w-1/2">
                    Игровой модуль состоит из двух решений: Образовательная головоломка -
                    3D-игра, в которой пользователь попадает в различные, сложные ситуации
                    негативного взаимодействия с цифровым миром. Для каждой темы предоставлены
                    свои начальные условия и предлагается выбор возможностей как справиться с данными проблемами.
                    Обучающая визуальная новелла для изучения цифровой гигиены с помощью реальной ситуации-истории.
                    Игрок погружается в историю с помощью изображений, видео, простых диалогов, музыки.
                    Вовлеченность игрока в историю повышается за счет выбора действий и демонстрации последствий
                    данного решения.
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-7 z-10 transparent-bg">
                <Link className="z-10 transparent-bg" to="https://game.info-gu.ru">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm"
                        style={{background: '#C61437', width: '200px', height: '35px'}}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Играть</div>
                    </Button>
                </Link>
            </div>
            <div className="mainmonkey-about mb-5">
                <img className="transparent-bg" src={mk_notebook} width={600} alt={"/"}/>
            </div>
            <div className="h-[35vh] transparent-bg"></div>
        </div>
    )
}