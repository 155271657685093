import { makeAutoObservable } from "mobx";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class RegisterStore {
    name = "";
    surname = "";
    patronymic = "";
    age = "";
    email = "";
    password = "";
    repeatPassword = "";
    isLoading = false;
    error = "";

    constructor() {
        makeAutoObservable(this);
    }

    setName(name: string) {
        this.name = name;
    }

    setSurname(surname: string) {
        this.surname = surname;
    }

    setPatronymic(patronymic: string) {
        this.patronymic = patronymic;
    }

    setAge(age: string) {
        this.age = age;
    }

    setEmail(email: string) {
        this.email = email;
    }

    setPassword(password: string) {
        this.password = password;
    }

    setRepeatPassword(repeatPassword: string) {
        this.repeatPassword = repeatPassword;
    }

    async register() {
        this.isLoading = true;
        this.error = "";

        try {
            const response = await axios.post(`${API_URL}/api/auths/signup`, {
                email: this.email,
                password: this.password,
                first_name: this.name,
                second_name: this.surname,
                patr_name: this.patronymic,
            });

            console.log("Registration successful:", response.data);
            alert("Вы успешно зарегистрированы!")
            // Handle successful registration, e.g., save tokens, redirect user, etc.
        } catch (error: any) {
            this.error = error.response?.data?.message || "Registration failed";
        } finally {
            this.isLoading = false;
        }
    }
}

export const registerStore = new RegisterStore();
