import { makeAutoObservable } from "mobx";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface NewsItem {
    id: number;
    title: string;
    content: string;
    image: string;
    createdAt: string;
    updatedAt: string;
}

class NewsStore {
    news: NewsItem[] = [];
    loading: boolean = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async fetchNews() {
        this.loading = true;
        try {
            const response = await axios.get<NewsItem[]>(`${API_URL}/api/news`);
            this.news = response.data;
        } catch (error) {
            this.error = 'Failed to fetch news';
        } finally {
            this.loading = false;
        }
    }
}

const newsStore = new NewsStore();
export default newsStore;
