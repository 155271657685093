import React from "react";
import { observer } from "mobx-react-lite";
import { registerStore } from "../data/mobx/registerStore";
import { Button, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";

export const RegisterPage = observer(() => {
    const navigate = useNavigate();  // Хук для навигации

    const onFinish = async (values: any) => {
        registerStore.setName(values.name);
        registerStore.setSurname(values.surname);
        registerStore.setPatronymic(values.patronymic);
        registerStore.setEmail(values.email);
        registerStore.setPassword(values.password);
        registerStore.setRepeatPassword(values.repeatpassword);

        await registerStore.register();

        // Перенаправление после успешной регистрации
        if (!registerStore.error) {
            navigate('/login');  // Перенаправление на главную страницу
        }
    };

    return (
        <div className="flex justify-center items-center h-[80vh]">
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ width: 300 }}
            >
                <div className="flex justify-center space-x-4 mb-5">
                    <a href="/login" className="text-2xl font-comfortaa font-extralight hover:text-raspberry">Вход</a>
                    <div className="text-2xl font-comfortaa font-extralight hover:no-hover-color">/</div>
                    <div className="text-2xl font-comfortaa text-raspberry font-extralight hover:text-raspberry">Регистрация</div>
                </div>

                <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please input your Name!' }]}
                >
                    <Input
                        type="name"
                        placeholder="Имя"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="surname"
                    rules={[{ required: true, message: 'Please input your Surname!' }]}
                >
                    <Input
                        type="surname"
                        placeholder="Фамилия"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="patronymic"
                    rules={[{ required: true, message: 'Please input your Patronymic!' }]}
                >
                    <Input
                        type="patronymic"
                        placeholder="Отчество"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your E-mail!' }]}
                >
                    <Input
                        type="email"
                        placeholder="E-Mail"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                    hasFeedback
                >
                    <Input
                        type="password"
                        placeholder="Пароль"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="repeatpassword"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли не совпадают!'));
                            },
                        }),
                    ]}
                >
                    <Input
                        type="password"
                        placeholder="Повторный пароль"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                {registerStore.error && <div style={{ color: 'red', marginBottom: '10px' }}>{registerStore.error}</div>}

                <Form.Item className="flex flex-col items-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm"
                        style={{ background: '#C61437', width: '200px', height: '35px' }}
                        loading={registerStore.isLoading}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Зарегистрироваться</div>
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
});
