import React from "react";
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { observer } from 'mobx-react-lite';
import articlesStore, { Article } from '../data/mobx/articlesStore';

const { Panel } = Collapse;

// Преобразуем текст с символами новой строки в HTML
const formatContent = (content: string): string => {
    return content.replace(/\n/g, '<br/>');
};

// Преобразуем статьи из хранилища в формат Collapse
const transformArticlesToCollapseItems = (articles: Article[]): CollapseProps['items'] => {
    return articles.map(article => ({
        key: article.id,
        label: <div style={{ color: '#C61437' }}>{article.title}</div>,
        children: <div dangerouslySetInnerHTML={{ __html: formatContent(article.content) }} />,
    }));
};

export const MaterialsPage = observer(() => {
    const outerItems: CollapseProps['items'] = transformArticlesToCollapseItems(articlesStore.articles);

    return (
        <div>
            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Учебные материалы
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-7 mb-7">
                <div className="w-full z-10">
                    <Collapse
                        items={outerItems}
                        className="font-comfortaa"
                        style={{ fontSize: '14px' }}
                    />
                </div>
            </div>
        </div>
    );
});
