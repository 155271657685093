import React from "react"
import pspu from "../images/pspu.png";
import orda from "../images/orda.jpg";

export function AboutPage() {
    return (
        <div>
            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Муниципальный фестиваль Интернет-БЕЗОПАСНОСТИ Цифровая защита школьника</div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-comfortaa text-greytext text-m sm:text-xl z-10">
                    Решение поставленных в проекте задач осуществляется на основе применения общенаучных методов исследования в рамках изучения надежных источников информации: книга И.Ашманова и Н.Касперской «Цифровая гигиена», анализа информации по результатам разработок студентами и учеными ПГГПУ, проведение опросов среди целевой аудитории (школьники и студенты IT-куба), апробации лекционных цифровых занятий по тематике угроз глобальных сетей.
                    Апробация и опытное тестирование системы на школах Ординском МО - Муниципальный фестиваль
                    Интернет-БЕЗОПАСНОСТИ
                    “Цифровая защита школьника”.
                </div>
            </div>
            <div className="flex justify-start ml-14 mr-14 mt-20">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Партнёры</div>
            </div>
            <div className="flex justify-start object-left-bottom space-x-4 ml-14 mr-14 mb-5">
                <a href={"https://pspu.ru/"}><img src={pspu} width={200} alt={"/"}/></a>
                <a href={"https://orda-adm.ru"}><img src={orda} width={50} alt={"/"}/></a>
            </div>
        </div>
    )
}