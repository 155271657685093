import React from "react"
import {Link} from "react-router-dom";
import { Button } from "antd";
import mk_book from "../images/mk_books.png";
import mk_notebook from "../images/notebook.png";

interface ModuleCardProps {
    title: string;
    imageSrc: string;
    buttonText: string;
    linkTo: string;
}

const ModuleCard: React.FC<ModuleCardProps> = ({ title, imageSrc, buttonText, linkTo }) => {
    return (
        <div className="transparent-white-bg rounded-lg shadow-lg p-6 mb-0 flex flex-col items-center space-y-4 min-w-[365px] min-h-[365px]">
            <h3 className="textabout font-rounds-black text-greytext text-xl z-10">{title}</h3>
            <img src={imageSrc} alt={title} className="w-[210px] h-[210px] transparent-bg"/>
            <Link className="z-10 transparent-bg" to={linkTo}>
                <Button type="primary" className="accpet-form-button mb-3 font-comfortaa text-sm"
                        style={{background: '#C61437', width: '200px', height: '35px'}}>
                    <div style={{backgroundColor: '#C61437'}}>{buttonText}</div>
                </Button>
            </Link>
        </div>
    );
};

export function ChooseTest() {
    return (
        <div className="bg-custom-background">
            <div className="transparent-bg flex justify-around items-center min-h-[88vh]">
                <ModuleCard
                    title="ОБРАЗОВАТЕЛЬНЫЙ МОДУЛЬ"
                    imageSrc={mk_book} // Замените на путь к вашему изображению
                    buttonText="Начать обучение"
                    linkTo="/aboutlearn"
                />
                <ModuleCard
                    title="ИГРОВОЙ МОДУЛЬ"
                    imageSrc={mk_notebook} // Замените на путь к вашему изображению
                    buttonText="Начать обучение"
                    linkTo="/aboutgame"
                />
            </div>
        </div>
    )
}