import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {AuthResponse} from "../../models/models";

export const infoguruApi = createApi({
    reducerPath: 'infoguru/api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://192.168.176.228:3000/api/',
        prepareHeaders: (headers:any) => {
            headers.set('Content-Type', 'application/json; charset=utf-8')
            return headers
        },
    }),
    endpoints: build => ({
        authentication: build.query<AuthResponse, any>({
            query: ({email, password}) => ({
                url: 'auths/signin',
                method: 'POST',
                mode: 'cors',
                body: {
                    email: email,
                    password: password
                }
            })
        })
    })
})

export const {useAuthenticationQuery} = infoguruApi
