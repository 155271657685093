import React from "react"
// @ts-ignore
import file from "../documents/polozhenie.pdf"

export function FestivalPage() {
    return (
        <div>
            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-rounds-black text-greytext text-xl z-10">
                    Фестиваль интернет-безопасности «Цифровая защита школьника»
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-comfortaa text-greytext text-m z-10">
                    <div>
                        Фестиваль интернет-безопасности «Цифровая защита школьника» является образовательным конкурсом
                        проектов формирования безопасной цифровой среды. Фестиваль проводится Пермским государственным
                        гуманитарно-педагогическим университетом, управление образования Ординского муниципального
                        округом,
                        ООО «ИНФОГУРУ» с целью повышения знаний и проектного опыта школьников в области цифровой
                        гигиены,
                        повышения осведомленности обучающихся об информационной безопасности и разработки проектов по
                        теме
                        «Как защитить себя от угроз Интернета».
                    </div>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-comfortaa font-bold text-greytext text-lg z-10">
                    <div>
                        Задачи фестиваля:
                    </div>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-2">
                <div className="textabout font-comfortaa text-greytext text-m z-10">
                    <ul>
                        <li> • Побуждение обучающихся школ Ординского муниципального округа детей к изучению и
                            использованию правил цифровой гигиены.
                        </li>
                        <li> • Выявление и развитие творческого потенциала обучающихся.</li>
                        <li> • Формирование у школьников устойчивых правил поведения в глобальных сети.</li>
                    </ul>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-comfortaa font-bold text-greytext text-lg z-10">
                    <div>
                        Этапы проведения Фестиваля:
                    </div>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-2">
                <div className="textabout font-comfortaa text-greytext text-m z-10">
                    <ul>
                        <li>
                            • I этап (с 8 апреля по 17 апреля 2024 г.) – школьный этап – регистрация участников,
                            выполнение заданий. Завершением школьного этапа является определение участников, которые
                            проходят на следующий, финальный этап Фестиваля.
                        </li>
                        <li>
                            • II этап (с 23 апреля по 27 апреля 2024 г.) – финальный этап Ординского муниципального
                            округа – защита проекта на тему «Как пользователю защититься от угроз Интернета».
                            Презентация разработанных проектов конкурсному жюри.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7">
                <div className="textabout font-comfortaa font-bold text-greytext text-lg z-10">
                    <div>
                        Завершением муниципального этапа является:
                    </div>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-2">
                <div className="textabout font-comfortaa text-greytext text-m z-10">
                    <ul>
                        <li>
                            • Награждение победителей и призеров Фестиваля.
                        </li>
                        <li>
                            • Награждение участников сертификатами и памятными подарками.
                        </li>
                        <li>
                            • Благодарственные письма преподавателям и руководителям школ подготовившим финалистов
                            конкурса.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="flex justify-start ml-14 mr-14 mt-7 mb-5">
                <div className="textabout font-comfortaa font-bold text-greytext text-m z-10">
                    <div>
                        <a href={file}>Положение фестиваль ЦБ округ.pdf</a><br/>
                    </div>
                </div>
            </div>

        </div>
    )
}