import React from "react";
import { observer } from "mobx-react-lite";
import { authStore } from "../data/mobx/authStore";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import { useNavigate } from "react-router-dom";


export const LoginPage = observer(() => {
    const navigate = useNavigate();  // Хук для навигации

    const onFinish = async (values: any) => {
        authStore.setEmail(values.email);
        authStore.setPassword(values.password);
        await authStore.login();

        // Перенаправление после успешной авторизации
        if (authStore.isAuthenticated) {
            navigate('/');  // Перенаправление на главную страницу
        }
    };

    return (
        <div className="flex justify-center items-center h-[80vh]">
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ width: 300 }}
            >
                <div className="flex justify-center space-x-4 mb-5">
                    <div className="text-2xl font-comfortaa font-extralight text-raspberry hover:text-raspberry">Вход</div>
                    <div className="text-2xl font-comfortaa font-extralight hover:no-hover-color">/</div>
                    <a href="/register" className="text-2xl font-comfortaa font-extralight hover:text-raspberry">Регистрация</a>
                </div>

                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your E-mail!' }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        type="email"
                        placeholder="E-Mail"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Пароль"
                        style={{ borderColor: '#C61437' }}
                    />
                </Form.Item>


                {authStore.error && <div style={{ color: 'red', marginBottom: '10px', marginTop: '-15px' }}>{authStore.error}</div>}


                <Form.Item className="flex flex-col items-center">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm"
                        style={{ background: '#C61437', width: '200px', height: '35px' }}
                        loading={authStore.isLoading}
                    >
                        <div style={{ backgroundColor: '#C61437' }}>Войти</div>
                    </Button>
                    <a className="hover:text-raspberry font-comfortaa text-xs flex justify-center" href="/forgot-password">Забыли пароль?</a>
                </Form.Item>
            </Form>
        </div>
    );
});
