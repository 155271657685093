import React, { useEffect } from "react";
import { Button } from 'antd';
import { Link } from "react-router-dom";
import mk_book from "../images/mk_books.png";
import { NavigationBottom } from "../components/NavigationBottom";
import { observer } from "mobx-react-lite";
import newsStore from "../data/mobx/newsStore";

const MainPage: React.FC = observer(() => {
    useEffect(() => {
        newsStore.fetchNews();
    }, []);

    if (newsStore.loading) {
        return <div className="flex justify-center items-center h-screen text-raspberry font-comfortaa">Загрузка...</div>;
    }

    if (newsStore.error) {
        return <div className="flex justify-center items-center h-screen text-raspberry font-comfortaa">Ошибка: {newsStore.error}</div>;
    }

    // Получаем три последних новости
    const latestNews = newsStore.news
        .slice()// Клонируем массив
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) // Сортируем по дате
        .slice(0, 3); // Берём только первые три

    return (
        <div className="bg-custom-background wrapper">
            <div className="flex justify-start ml-10 mr-10 mt-5 sm:mt-8 transparent-bg">
                <div className="textmainpage font-rounds-black text-greytext text-2xl sm:text-4xl z-10">
                    НАУЧИСЬ РАЗБИРАТЬСЯ В ТОМ, ЧЕМ ПОЛЬЗУЕШЬСЯ КАЖДЫЙ ДЕНЬ
                </div>
            </div>
            <div className="flex justify-start ml-10 mr-10 mt-4 bg-opacity-100 transparent-bg">
                <div className="textmainpagesmall font-comfortaa text-greytext text-xs sm:text-lg z-10 bg-opacity-100">
                    Система по изучению правил безопасности в интернет пространстве.
                </div>
            </div>
            <div className="flex justify-start ml-10 mr-10 mt-4 z-10 transparent-bg">
                <Link className="z-10 transparent-bg" to="/choose">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mb-3 font-comfortaa text-sm"
                        style={{background: '#C61437', width: '200px', height: '35px'}}
                    >
                        <div style={{backgroundColor: '#C61437'}}>Курс</div>
                    </Button>
                </Link>
            </div>
            <div className="mainmonkey mb-5">
                <img className="transparent-bg" src={mk_book} width={600} alt={"/"}/>
            </div>

            {/* Добавляем фиксированный отступ для новостей ниже экрана */}
            <div className="h-[60vh] transparent-bg"></div>

            <div className="flex justify-start ml-10 mr-10 mt-5 sm:mt-8 transparent-bg">
                <div className="textmainpage font-rounds-black text-greytext text-2xl sm:text-4xl z-10">
                    НОВОСТИ
                </div>
            </div>
            {/* Горизонтально скроллируемый блок с новостями */}
            <div className="ml-10 mr-10 mt-5 flex justify-center transparent-bg">
                <div className="overflow-x-auto flex space-x-3 sm:space-x-14 transparent-bg scrollbar-hide">
                    {latestNews.map((news) => (
                        <Link to="/news" key={news.id} className="w-screen sm:w-[400px] bg-white shadow-md rounded-lg p-3">
                            <img src={news.image} alt={news.title}
                                 className="w-full h-50 object-cover rounded-lg mb-2"/>
                            <div className="font-bold font-comfortaa text-sm text-raspberry">{news.title}</div>
                            <div className="font-comfortaa text-xs text-gray-600 line-clamp-3"
                                 style={{
                                     display: '-webkit-box',
                                     WebkitBoxOrient: 'vertical',
                                     WebkitLineClamp: 3,
                                     overflow: 'hidden',
                                     textOverflow: 'ellipsis',
                                 }}
                            >
                                {news.content}
                            </div>
                        </Link>
                    ))}
                </div>
            </div>

            {/* Добавляем фиксированный отступ */}
            <div className="h-[10vh] transparent-bg"></div>
            <NavigationBottom/>
        </div>
    );
});

export default MainPage;
