import { makeAutoObservable } from 'mobx';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Определяем тип для статьи
export interface Article {
    id: string;
    title: string;
    content: string;
}

class ArticlesStore {
    articles: Article[] = [];

    constructor() {
        makeAutoObservable(this);
        this.fetchArticles();
    }

    async fetchArticles() {
        try {
            // Добавляем параметр с временной меткой для предотвращения кэширования
            const response = await axios.get<Article[]>(`${API_URL}/api/articles?_=${new Date().getTime()}`);
            this.articles = response.data;
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    }
}

const articlesStore = new ArticlesStore();
export default articlesStore;
