import React from "react"
import {Link} from "react-router-dom";
import Button from "@mui/joy/Button";
export function TestPageTwo() {
    return(
        <div className="test">
            <div className="flex justify-center text-xs sm:text-lg mb-2">
                <div className="font-comfortaa m-2">Тест 2: "Компьютерная грамотность"</div>
            </div>
            <div className="flex justify-center">
                <script src="https://forms.yandex.ru/_static/embed.js"></script>
                <iframe title="Test 2" src="https://forms.yandex.ru/u/65ff147743f74f3a4ba006d3/?iframe=1" frameBorder="0"
                        name="ya-form-65ff147743f74f3a4ba006d3" width="350" height="5195"></iframe>
            </div>
            <div className="flex justify-center mt-7 z-10">
                <Link to="/choose">
                    <Button variant={"solid"} sx={{backgroundColor: "#C61437", ":hover": {backgroundColor: "#850e25"}, marginBottom: 5, width: 300, height: 50, borderRadius: 30, font:"Comfortaa"}}>Вернуться назад</Button>
                </Link>
            </div>
        </div>
    )
}